<template>
  <el-dialog title="二维码"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible"
             width="30%">
    <div id="code"
         ref="code"></div>
    <a :href="url"
       class="mt-15">{{url}}</a>
  </el-dialog>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  props: {
    url: String
  },
  data () {
    return {
      dialogVisible: false,
      qrcode: {}
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    getQRCode () {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.code.innerHTML = ''
        this.modal1 = true
        this.qrcode = new QRCode('code', {
          width: 200,
          height: 200,
          text: this.url, // 二维码地址
          colorDark: '#000',
          colorLight: '#fff'
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
